import React from 'react';

import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

export const CardElement = ({ name = "Overall", revenue = 0, awardCount = 0, ltcRevenue = 0, nonLtcRevenue = 0, winPct = 0, ltcWinPct = 0, nonLtcWinPct = 0, qty = 0 }) => {
    return (
        <>
            <div className="p-1 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <div className="card-element color1" style={{ fontSize: '12px' }}>
                    <div className="row m-0 p-0">
                        <div className="col m-0 p-0">
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th colSpan="2" style={{ fontSize: '24px' }}>{CurrencyFormatter.format(revenue)}</th>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td>LTC:</td>*/}
                                {/*    <td className="light">{CurrencyFormatter.format(ltcRevenue)}</td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td>NonLTC:</td>*/}
                                {/*    <td className="light">{CurrencyFormatter.format(nonLtcRevenue)}</td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td>Awards:</td>
                                    <td className="light">{awardCount}</td>
                                </tr>
                                <tr>
                                    <td>Win Percent:</td>
                                    <td className="light">{PercentageFormatter.format(winPct)}</td>
                                </tr>

                                <tr>
                                    <td>Quantity:</td>
                                    <td className="light">{qty}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="col m-0 p-0 d-flex align-items-end">
                            <table style={{ width: '100%' }}>
                                {/*<tr>*/}
                                {/*    <td>Awards:</td>*/}
                                {/*    <td className="light">{awardCount}</td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td>Win Percent:</td>*/}
                                {/*    <td className="light">{PercentageFormatter.format(winPct)}</td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td>NonLTC Wins:</td>*/}
                                {/*    <td className="light">{PercentageFormatter.format(nonLtcWinPct)}</td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td>LTC Wins:</td>*/}
                                {/*    <td className="light">{PercentageFormatter.format(ltcWinPct)}</td>*/}
                                {/*</tr>*/}
                            </table>
                        </div>
                    </div>
                    <div className="row m-0 p-0 mt-3 d-flex align-items-center" style={{ borderTop: 'solid 0.5px rgba(255, 255, 255, 0.30)' }}>
                        <h5 className="pt-2 pb-0 mb-1" style={{ fontSize: '14px', letterSpacing: '1px', fontWeight: 600 }}>
                            { name } | LTC | NonLTC.
                        </h5>
                    </div>
                </div>
            </div>
        </>
    );
};