import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

import { NiinRow } from '../shared/NiinRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

export const AdvancedMarketTopNiinsTable = ({ rows, style }) => {

    const navigate = useNavigate();
    const routeChange = (niin) => {
        let path = `/usg/parts/search/view/${niin}`;
        navigate(path);
    }

    const procHistColumns = [
        {
            name: 'NIIN',
            selector: row => row.niin,
            cell: (row, ix) => <>
                <NiinRow ix={ix} niin={row.niin} tablename={"tan"} />
            </>
        },
        {
            name: 'Part Number(s)',
            selector: row => row.part_numbers ?? "N/A",
            cell: row => <>
                {
                    row.part_numbers != null && row.part_numbers.length > 0 ?
                        <a style={{ 'cursor': 'pointer' }} title={row.part_numbers.toString()}><FontAwesomeIcon icon={faMagnifyingGlass} /></a>
                        : "No Approval"
                }
                
            </>
        },
        {
            name: 'Classification',
            selector: row => row.item_classification,
        },
        //{
        //    name: 'Name',
        //    selector: row => row.part_name,
        //},
        {
            name: 'Awards',
            selector: row => row.award_count,
        },
        {
            name: 'Award Value',
            selector: row => CurrencyFormatter.format(row.total_value),
            sortable: true
        },
        {
            name: 'Win Value',
            selector: row => CurrencyFormatter.format(row.win_value),
            sortable: true
        }
    ]

    return (
        <>
            <DataTable
                columns={procHistColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}