import React from 'react'
import { PageHeader } from '../../components/shared/PageHeader'
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faCalendarDays, faChartSimple, faFileCsv } from '@fortawesome/free-solid-svg-icons';

export const ReportsList = () => {

    const navigate = useNavigate()

    return (
        <>
            <PageHeader pageName={"Reports"} />

            <br />
            <br />

            <div className="m-0 p-0 col-lg-6 col-12 mb-4 card-element">
                <div id="accordion1">
                    <div className="card">
                        <div className="card-header">
                            <div className="col-12">
                                <div className="row">
                                    <span onClick={() => navigate("/reports/forecast")} style={{ cursor: 'pointer' }}>
                                        <h5 style={{
                                            textTransform: "uppercase",
                                            fontSize: '1.5vw',
                                            letterSpacing: '3px'
                                        }}>Forecasting</h5>
                                    </span>
                                    {/*<h6><span class="mt-2 ml-3 badge badge-info">Beta</span></h6>*/}
                                </div>
                            </div>
                        </div>
                    

                        {/*<div className="card-header p-0" id="forecastReports">*/}
                        {/*    <h6 className="mb-0">*/}
                        {/*        <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseForecastReports" aria-expanded="false" aria-controls="collapseForecastReports" style={{ padding: '0' }} >*/}
                        {/*            <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />*/}
                        {/*        </button>*/}

                        {/*        <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '100px' }}>Saved Reports</span>*/}

                        {/*    </h6>*/}
                        {/*</div>*/}

                        {/*<div id="collapseForecastReports" className="collapse" aria-labelledby="forecastReports" data-parent="#accordion1">*/}
                        {/*    <div className="card-body accordian">*/}
                        {/*        <h6>Coming Soon ...</h6>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <div className="m-0 p-0 col-lg-6 col-12 mb-4 card-element">
                <div id="accordion2">
                    <div className="card">
                        <div className="card-header">
                            <div className="col-12">
                                <div className="row">
                                    <span onClick={() => navigate("/reports/advanced_market")} style={{ cursor: 'pointer' }}>
                                        <h5 style={{
                                            textTransform: "uppercase",
                                            fontSize: '1.5vw',
                                            letterSpacing: '3px'
                                        }}>Advanced Market</h5>
                                    </span>
                                    {/*<h6><span class="mt-2 ml-3 badge badge-info">Beta</span></h6>*/}
                                </div>
                            </div>
                        </div>


                        {/*<div className="card-header p-0" id="advancedMarketReports">*/}
                        {/*    <h6 className="mb-0">*/}
                        {/*        <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseAdvancedMarketReports" aria-expanded="false" aria-controls="collapseAdvancedMarketReports" style={{ padding: '0' }} >*/}
                        {/*            <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />*/}
                        {/*        </button>*/}

                        {/*        <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '100px' }}>Saved Reports</span>*/}

                        {/*    </h6>*/}
                        {/*</div>*/}

                        {/*<div id="collapseAdvancedMarketReports" className="collapse" aria-labelledby="advancedMarketReports" data-parent="#accordion2">*/}
                        {/*    <div className="card-body accordian">*/}
                        {/*        <h6>Coming Soon ...</h6>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

        </>
    )
}