import React from 'react';

import { saveAs } from 'file-saver';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faPhotoFilm, faThumbTack, faX } from '@fortawesome/free-solid-svg-icons';

import { ForecastDemand } from './Charts/ForecastDemand';
import { ForecastDemandByClassification } from './Charts/ForecastedDemandByClassification';



export const ForecastChartDashboard = ({ forecast = [], startDate = "", endDate = "" }) => {
    const [chartType, setChartType] = React.useState("forecastDemand");
    const [chartCategory, setChartCategory] = React.useState("forecast");
    const [pinnedCharts, setPinnedCharts] = React.useState([]);

    const handleChangeChartType = (event, type) => {
        setChartType(type);
        event.preventDefault();
    };

    const handleChangeChartCategory = (event, cat) => {
        setChartCategory(cat);
        event.preventDefault();
    };

    const handleChartImageExport = (event, type) => {
        event.preventDefault();
        saveCanvas(type);
    }

    const saveCanvas = (id) => {
        //save to png
        const canvasSave = document.getElementById(id);
        canvasSave.toBlob(function (blob) {
            saveAs(blob, id + ".png")
        })
    }

    const handleChartDataExport = (event, type) => {
        event.preventDefault();
        let chart = charts.find((item) => {
            return item.id === type;
        })

        let items = chart.data;

        let csv;
        for (let row = 0; row < items.length; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if (row === 0) {

                // Loop each property of the object
                for (let key in items[row]) {

                    // This is to not add a comma at the last cell
                    // The '\r\n' adds a new line
                    csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
            } else {
                for (let key in items[row]) {
                    csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
            }

            keysCounter = 0
        }

        // Once we are done looping, download the .csv by creating a link
        let link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', type + '.csv');
        document.body.appendChild(link)
        document.querySelector('#download-csv').click()
    }

    const handleChartPin = (event, type) => {
        event.preventDefault();
        if (pinnedCharts.map(i => i.id).includes(type)) { return; }

        setPinnedCharts((prev) => [
            charts.find((item) => {
                return item.id == type;
            }), ...prev
        ]);

    }

    const handleChartUnpin = (event, type) => {
        event.preventDefault();
        if (!pinnedCharts.map(i => i.id).includes(type)) { return; }

        setPinnedCharts((prev) => [...prev.filter(item => item.id !== type)]);

    }

    const charts = [
        {
            id: "forecastDemand",
            name: "Government Est. Demand",
            category: "forecast",
            data: forecast,
            renderChart: function () {
                return (
                    <>
                        <ForecastDemand forecast={this.data} />
                    </>
                );
            }
        },
        {
            id: "forecastDemandByClassification",
            name: "Gov. Est. Demand Per Classification",
            category: "forecast",
            data: forecast,
            renderChart: function () {
                return (
                    <>
                        <ForecastDemandByClassification forecast={this.data} />
                    </>
                );
            }
        }
    ];

    return (
        <>
            <div className="row" style={{ width: "100%" }}>
                <div className="m-0 ml-3 p-2 pl-4 pr-4 col-11 col-xl-8 color1 card-element">
                    <div style={{ borderBottom: '1px solid white' }}>
                        <div className="card" style={{ border: '0' }}>

                            <div className="card-header p-0">
                                <div className="row p-0 mt-1 justify-content-between text-right">
                                    <div className="col-9 text-left">
                                        <h4>
                                            {
                                                charts.find(obj => {
                                                    return obj.id === chartType

                                                }).name
                                            }
                                        </h4>
                                    </div>
                                    <div className="col-3">
                                        <span className="" title="Export To PNG" style={{ cursor: 'pointer' }} onClick={(event) => handleChartImageExport(event, chartType)}><FontAwesomeIcon icon={faPhotoFilm} /></span>
                                        <span className="ml-3" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleChartDataExport(event, chartType)}><FontAwesomeIcon icon={faFileCsv} /></span>
                                        {!pinnedCharts.some(item => item.id === chartType) ? <span className="ml-3" title="Pin to Report" style={{ cursor: 'pointer' }} onClick={(event) => handleChartPin(event, chartType)}><FontAwesomeIcon icon={faThumbTack} /></span> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        {
                            charts.find(obj => {
                                return obj.id === chartType
                            }).renderChart()
                        }
                    </div>
                </div>
                <div className="col-12 col-xl-3 m-0">
                    <div className="row p-4 pt-4 d-flex justify-content-between">
                        <div className="col-12 pt-2">
                            <p
                                className=""
                                style={{
                                    letterSpacing: '3px',
                                    fontSize: '13px',
                                    opacity: '90%',
                                }}
                            >
                                Sort By Category:
                            </p>
                            <div className="row justify-content-between">
                                <div className="col-12">
                                    <label className="btn btn-primary chart-cat-btn m-1 pt-1 pb-1">
                                        <input type="radio" name="options" id="option1" onChange={(event) => handleChangeChartCategory(event, "forecast")} disabled /> Forecast
                                    </label>
                                    <label className="btn btn-primary chart-cat-btn-disabled m-1 pt-1 pb-1">
                                        <input type="radio" name="options" id="option2" onChange={(event) => handleChangeChartCategory(event, "consumption")} disabled /> Consumption
                                    </label>
                                    <label className="btn btn-primary chart-cat-btn-disabled m-1 pt-1 pb-1">
                                        <input type="radio" name="options" id="option3" onChange={(event) => handleChangeChartCategory(event, "capture")} disabled /> Capture
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            charts.filter(item => item.category === chartCategory).map((item, index) => {
                                return (
                                    <div className="col-12 mt-1">

                                        <button className="btn chart-select-btn" onClick={(event) => { handleChangeChartType(event, item.id) }} style={index == 0 ? { borderTop: '1px solid grey' } : {}}>
                                            {item.name}
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {
                pinnedCharts.length > 0 ?
                    <div className="col-md-12">
                        <div className="row">
                            {
                                pinnedCharts.map((item, index) => {
                                    return (
                                        <div className="col-lg-6 m-0 p-0 pr-1 mt-4">
                                            <div className="card-element m-0 p-2" style={{ position: "relative", margin: "auto" }}>

                                                <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                                    <div className="card" style={{ border: '0' }}>

                                                        <div className="card-header p-0">
                                                            <div className="row p-0 mt-1 justify-content-between text-center">
                                                                <div className="col-10 text-left">
                                                                    <h5>
                                                                        {item.name}
                                                                    </h5>
                                                                </div>
                                                                <div className="col-2">
                                                                    <span disabled className="" title="Export To PNG" style={{ cursor: 'pointer' }} onClick={(event) => handleChartImageExport(event, item.id)}><FontAwesomeIcon icon={faPhotoFilm} /></span>
                                                                    <span className="ml-3" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleChartDataExport(event, item.id)}><FontAwesomeIcon icon={faFileCsv} /></span>
                                                                    <span className="ml-3" title="Pin to Report" style={{ cursor: 'pointer' }} onClick={(event) => handleChartUnpin(event, item.id)}><FontAwesomeIcon icon={faX} /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    item.renderChart()
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    : null
            }

        </>
    )
}
