import React, { useEffect, useRef, useState } from "react";
import DataTable from 'react-data-table-component';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { ReportsApi } from "../../api/ReportsApi";
import { PageHeader } from '../../components/shared/PageHeader';
import { TableProgressBar } from '../../components/shared/TableProgressBar';

import { CardElement } from '../../components/reports/AdvancedMarketHeaderCard';
import { ChartSelector } from '../../components/reports/AdvancedMarketChartSelector';
import { AdvancedMarketProcHistTable } from '../../components/reports/AdvancedMarketProcHistTable';
import { AdvancedMarketTopNiinsTable } from '../../components/reports/AdvancedMarketTopNiinsTable';
import { AdvancedMarketTopAwardedCagesTable } from '../../components/reports/AdvancedMarketTopAwardedCagesTable';
import { AdvancedMarketTopWsdcTable } from '../../components/reports/AdvancedMarketTopWeaponSystemsTable';
import { AdvancedMarketTopFscsTable } from '../../components/reports/AdvancedMarketTopAwardedFscsTable';
import { AdvancedMarketTopApprovedCagesTable } from '../../components/reports/AdvancedMarketTopApprovedCagesTable';
import { AdvancedMarketChartDashboard } from '../../components/reports/AdvancedMarketChartDashboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faCalendarDays, faChartSimple, faFileCsv } from '@fortawesome/free-solid-svg-icons';

import BeatLoader from 'react-spinners/BeatLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import GridLoader from 'react-spinners/GridLoader';

import { Input } from 'reactstrap';

import CreatableSelect from 'react-select/creatable';


export const ReportsAdvancedMarket = ({ theme }) => {

    const [dataLoading, setDataLoading] = React.useState(false);
    const [exportLoading, setExportLoading] = React.useState(false);

    const [headerData, setHeaderData] = React.useState(null);

    const [approvedCagesInputValue, setApprovedCagesInputValue] = React.useState('');
    const [approvedCagesValue, setApprovedCagesValue] = React.useState(JSON.parse(localStorage.getItem('amrQueryParams'))?.approvedCages ?? []);

    const [distCagesInputValue, setDistCagesInputValue] = React.useState('');
    const [distCagesValue, setDistCagesValue] = React.useState(JSON.parse(localStorage.getItem('amrQueryParams'))?.distCages ?? []);

    const [awardeeCagesInputValue, setAwardeeCagesInputValue] = React.useState('');
    const [awardeeCagesValue, setAwardeeCagesValue] = React.useState(JSON.parse(localStorage.getItem('amrQueryParams'))?.awardeeCages ?? []);

    const [startDateValue, setStartDateValue] = React.useState(JSON.parse(localStorage.getItem('amrQueryParams'))?.startDate ?? '');
    const [endDateValue, setEndDateValue] = React.useState(JSON.parse(localStorage.getItem('amrQueryParams'))?.endDate ?? '');

    const [lockedApprovedCagesValue, setLockedApprovedCagesValue] = React.useState([]);
    const [lockedDistCagesValue, setLockedDistCagesValue] = React.useState([]);
    const [lockedAwardeeCagesValue, setLockedAwardeeCagesValue] = React.useState([]);
    const [lockedStartDateValue, setLockedStartDateValue] = React.useState('');
    const [lockedEndDateValue, setLockedEndDateValue] = React.useState('');

    const [procHistRows, setProcHistRows] = React.useState([]);
    const [procHistRowsLoading, setProcHistRowsLoading] = React.useState(false);

    const [niinDetailRows, setNiinDetailRows] = React.useState([]);
    const [niinDetailRowsLoading, setNiinDetailRowsLoading] = React.useState(false);

    const [topAwardedCagesRows, setTopAwardedCagesRows] = React.useState([]);
    const [topAwardedCagesRowsLoading, setTopAwardedCagesRowsLoading] = React.useState(false);

    const [wsdcDetailRows, setWsdcDetailRows] = React.useState([]);
    const [wsdcDetailRowsLoading, setWsdcDetailRowsLoading] = React.useState(false);

    const [fscDetailRows, setFscDetailRows] = React.useState([]);
    const [fscDetailRowsLoading, setFscDetailRowsLoading] = React.useState(false);

    const [topApprovedCagesRows, setTopApprovedCagesRows] = React.useState([]);
    const [topApprovedCagesRowsLoading, setTopApprovedCagesRowsLoading] = React.useState(false);

    const pdfRef = React.useRef();

    //const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

    //const updateMedia = () => {
    //    setDesktop(window.innerWidth > 1450);
    //};

    //useEffect(() => {
    //    window.addEventListener("resize", updateMedia);
    //    return () => window.removeEventListener("resize", updateMedia);
    //});

    const createOption = (label) => ({
        key: label,
        label,
        value: label
    });

    const handleApprovedCagesKeyDown = (event) => {
        if (!approvedCagesInputValue) return;
        switch (event.key) {
            case ' ':
            case 'Enter':
            case 'Tab':
                setApprovedCagesValue((prev) => [...prev, createOption(approvedCagesInputValue.toUpperCase())]);
                setApprovedCagesInputValue('');
                event.preventDefault();
        }
    };

    const handleDistCagesKeyDown = (event) => {
        if (!distCagesInputValue) return;
        switch (event.key) {
            case ' ':
            case 'Enter':
            case 'Tab':
                setDistCagesValue((prev) => [...prev, createOption(distCagesInputValue.toUpperCase())]);
                setDistCagesInputValue('');
                event.preventDefault();
        }
    };

    const handleAwardeeCagesKeyDown = (event) => {
        if (!awardeeCagesInputValue) return;
        switch (event.key) {
            case ' ':
            case 'Enter':
            case 'Tab':
                setAwardeeCagesValue((prev) => [...prev, createOption(awardeeCagesInputValue.toUpperCase())]);
                setAwardeeCagesInputValue('');
                event.preventDefault();
        }
    }

    const handleStartDateChange = (event) => {
        setStartDateValue(event.target.value);
        event.preventDefault();
    };

    const handleEndDateChange = (event) => {
        setEndDateValue(event.target.value);
        event.preventDefault();
    };

    const getHeader = () => {
        setDataLoading(true);

        return new Promise((resolve, reject) => {
            ReportsApi.advancedMarket({
                approvedCages: approvedCagesValue.map(a => a.value).join(","),
                distributionCages: distCagesValue.length > 0 ? distCagesValue.map(a => a.value).join(",") : null,
                awardeeCages: awardeeCagesValue.length > 0 ? awardeeCagesValue.map(a => a.value).join(",") : null,
                startDate: startDateValue,
                endDate: endDateValue
            }).then((response) => {
                setHeaderData(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                setDataLoading(false);
            });
        });
    }

    const getProcHist = () => {
        setProcHistRowsLoading(true);
        return new Promise((resolve, reject) => {
            ReportsApi.advancedMarketProcHist({
                approvedCages: approvedCagesValue.map(a => a.value).join(","),
                distributionCages: distCagesValue.length > 0 ? distCagesValue.map(a => a.value).join(",") : null,
                awardeeCages: awardeeCagesValue.length > 0 ? awardeeCagesValue.map(a => a.value).join(",") : null,
                startDate: startDateValue,
                endDate: endDateValue
            }).then((response) => {
                setProcHistRows(response.data);
                setProcHistRowsLoading(false);
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
            });
        });
    }

    const getNiinDetail = () => {
        setNiinDetailRowsLoading(true);
        return new Promise((resolve, reject) => {
            ReportsApi.advancedMarketNiinDetail({
                approvedCages: approvedCagesValue.map(a => a.value).join(","),
                distributionCages: distCagesValue.length > 0 ? distCagesValue.map(a => a.value).join(",") : null,
                awardeeCages: awardeeCagesValue.length > 0 ? awardeeCagesValue.map(a => a.value).join(",") : null,
                startDate: startDateValue,
                endDate: endDateValue
            }).then((response) => {
                setNiinDetailRows(response.data);
                setNiinDetailRowsLoading(false);
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
            });
        });
    }

    const getTopAwardedCages = () => {
        setTopAwardedCagesRowsLoading(true);
        return new Promise((resolve, reject) => {
            ReportsApi.advancedMarketTopAwardedCages({
                approvedCages: approvedCagesValue.map(a => a.value).join(","),
                distributionCages: distCagesValue.length > 0 ? distCagesValue.map(a => a.value).join(",") : null,
                awardeeCages: awardeeCagesValue.length > 0 ? awardeeCagesValue.map(a => a.value).join(",") : null,
                startDate: startDateValue,
                endDate: endDateValue
            }).then((response) => {
                setTopAwardedCagesRows(response.data);
                setTopAwardedCagesRowsLoading(false);
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
            });
        });
    }

    const getWSDCDetail = () => {
        setWsdcDetailRowsLoading(true);
        return new Promise((resolve, reject) => {
            ReportsApi.advancedMarketWSDCDetail({
                approvedCages: approvedCagesValue.map(a => a.value).join(","),
                distributionCages: distCagesValue.length > 0 ? distCagesValue.map(a => a.value).join(",") : null,
                startDate: startDateValue,
                endDate: endDateValue
            }).then((response) => {
                setWsdcDetailRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                setWsdcDetailRowsLoading(false)
            });
        });
    };

    const getFscDetail = () => {
        setFscDetailRowsLoading(true);
        return new Promise((resolve, reject) => {
            ReportsApi.advancedMarketFSCDetail({
                approvedCages: approvedCagesValue.map(a => a.value).join(","),
                distributionCages: distCagesValue.length > 0 ? distCagesValue.map(a => a.value).join(",") : null,
                awardeeCages: awardeeCagesValue.length > 0 ? awardeeCagesValue.map(a => a.value).join(",") : null,
                startDate: startDateValue,
                endDate: endDateValue
            }).then((response) => {
                setFscDetailRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                setFscDetailRowsLoading(false)
            });
        });
    }

    const getTopCagesByApproval = () => {
        setTopApprovedCagesRowsLoading(true);
        return new Promise((resolve, reject) => {
            ReportsApi.advancedMarketTopCagesByApproval({
                approvedCages: approvedCagesValue.map(a => a.value).join(","),
                distributionCages: distCagesValue.length > 0 ? distCagesValue.map(a => a.value).join(",") : null,
                startDate: startDateValue,
                endDate: endDateValue
            }).then((response) => {
                setTopApprovedCagesRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                setTopApprovedCagesRowsLoading(false);
            });
        });
    }

    const handleFormSubmit = (event) => {

        if (approvedCagesValue.length == 0) {
            event.preventDefault();
            alert('Must provide at least one approved cage code.');
            return;
        }

        setHeaderData(null);
        setProcHistRows([]);
        setNiinDetailRows([]);
        setTopAwardedCagesRows([]);

        getHeader();
        getProcHist();
        getNiinDetail();
        getTopAwardedCages();
        getWSDCDetail();
        getFscDetail();
        getTopCagesByApproval();
        saveInStorage();

        setLockedApprovedCagesValue([...approvedCagesValue]);
        setLockedDistCagesValue([...distCagesValue]);
        setLockedAwardeeCagesValue([...awardeeCagesValue]);
        setLockedStartDateValue(startDateValue);
        setLockedEndDateValue(endDateValue);
        event.preventDefault();
    }

    const handleExportForm = (event, items, type) => {
        event.preventDefault();
        if (!headerData) return;
        exportCsv(items, type);
    }

    const handleDownloadPdf = (event) => {
        event.preventDefault();
        downloadPdf();
    }

    const saveInStorage = () => {
        localStorage.setItem('amrQueryParams', JSON.stringify({
            approvedCages: approvedCagesValue,
            distCages: distCagesValue,
            awardeeCages: awardeeCagesValue,
            startDate: startDateValue,
            endDate: endDateValue
        }))
    }

    const handleClearCriteria = (event) => {
        setApprovedCagesValue(prev => []);
        setDistCagesValue(prev => []);
        setAwardeeCagesValue(prev => []);
        setStartDateValue('');
        setEndDateValue('');
        localStorage.removeItem('amrQueryParams');
        event.preventDefault();
    }

    const exportProcHist = () => {
        setExportLoading(true);
        ReportsApi.exportProcHist({
            approvedCages: lockedApprovedCagesValue.map(a => a.value).join(","),
            distributionCages: lockedDistCagesValue.length > 0 ? lockedDistCagesValue.map(a => a.value).join(",") : null,
            awardeeCages: lockedAwardeeCagesValue.length > 0 ? lockedAwardeeCagesValue.map(a => a.value).join(",") : null,
            startDate: lockedStartDateValue,
            endDate: lockedEndDateValue
        })
        .then(res => {
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data}`;
            const downloadLink = document.createElement('a');
            const fileName = 'prochist.xlsx';

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        })
        .catch(err => console.log(err))
        .finally(() => setExportLoading(false));
    }

    const exportCsv = (items, type) => {
        let csv = ""

        // Loop the array of objects
        for (let row = 0; row < items.length; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if (row === 0) {

                // Loop each property of the object
                for (let key in items[row]) {

                    // This is to not add a comma at the last cell
                    // The '\r\n' adds a new line
                    csv += '"' + key + '"' + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
            } else {
                for (let key in items[row]) {
                    csv += '"' + items[row][key] + '"' + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
            }

            keysCounter = 0
        }

        // Once we are done looping, download the .csv by creating a link
        let link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', type + '.csv');
        document.body.appendChild(link)
        document.querySelector('#download-csv').click()
    }

    const downloadPdf = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 0;

            console.log(imgWidth);
            pdf.addImage(imgData, 'PNG', 0, imgY, pdfWidth, pdfHeight*1.4);
            pdf.save('amr.pdf');
        })
    }

    const customStylesDark = {
        table: {
            style: {
                fontSize: '12px'
            }
        },
        header: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        headRow: {

            style: {
                padding: "6px 8px 6px 8px",
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        rows: {
            style: {
                padding: "6px 8px 6px 8px",
                '&:nth-of-type(even)': {
                    color: 'white',
                    backgroundColor: '#1D2839'
                },
                '&:nth-of-type(odd)': {
                    color: 'white',
                    backgroundColor: '#39445C'
                },
            },
        },
        pagination: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
                borderTop: '1px solid white'
            }
        }
    };

    const renderPdf = () => {
        return (
            <div ref={pdfRef} style={{ backgroundColor: '#222D3E', padding: '10px', margin: '-10px'}}>
                <PageHeader pageName="Advanced Market Report" />
                {
                    renderBody()
                }
            </div>
        );
    }

    const renderBody = () => {
        return (
            <>
                <div className="container-fluid">
                    <div className="row m-0 mb-4 p-0 flex-nowrap overflow-auto" style={{
                        fontSize: '13px',
                        maxHeight: '200px',
                    }}>
                        <CardElement name={"Overall Revenue"} revenue={headerData.overall_revenue} awardCount={headerData.overall_award_count} qty={headerData.overall_quantity} winPct={headerData.overall_win_percent} />
                        <CardElement name={"Approved Cages"} revenue={headerData.approved_revenue} awardCount={headerData.approved_award_count} qty={headerData.approved_quantity} winPct={headerData.approved_win_percent} />
                        <CardElement name={"Distribution"} revenue={headerData.distribution_revenue} awardCount={headerData.distribution_award_count} qty={headerData.distribution_quantity} winPct={headerData.distribution_win_percent} />
                        <CardElement name={"Partnership"} revenue={headerData.partnership_revenue} awardCount={headerData.partnership_award_count} qty={headerData.partnership_quantity} winPct={headerData.overall_win_percent}/>
                    </div>                
                {
                    procHistRows.length > 0
                        ?
                        <div className="row m-0 mb-4 p-0" style={{
                            fontSize: '13px',
                            overflow: 'hidden'
                        }} >
                            <AdvancedMarketChartDashboard history={procHistRows} />
                        </div>
                        : null
                }

                {/*<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>*/}

                <div className="col-md-12">
                    <div className="row">
                        <div className="col-lg-6 m-0 p-0 pr-1 mt-4">
                            <div className="card-element m-0 p-1">

                                <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                    <div className="card" style={{ border: '0' }}>

                                        <div className="card-header p-0">
                                            <div className="row p-0 mt-1 justify-content-between text-center">
                                                <div className="col-11 text-left">
                                                    <h5 className="mb-0">
                                                        <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Top Awarded NIIN's</span>
                                                    </h5>
                                                </div>
                                                <div className="col-1 text-center mt-1">
                                                    <span className="" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleExportForm(event, niinDetailRows, 'topAwardedNiins')}><FontAwesomeIcon icon={faFileCsv} /></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {
                                    niinDetailRows.length > 0 ?
                                        <AdvancedMarketTopNiinsTable rows={niinDetailRows} style={customStylesDark} />
                                        : <div className="row m-0 p-4" style={{
                                            textAlign: 'center'
                                        }}>
                                            <div className="col">
                                                {
                                                    niinDetailRowsLoading ?
                                                        <BeatLoader color={"#BF663F"} />
                                                        : <div className="row mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="col-lg-6 m-0 p-0 pl-1 mt-4">
                            <div className="card-element m-0 p-1">
                            <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                <div className="card" style={{ border: '0' }}>

                                    <div className="card-header p-0">
                                        <div className="row p-0 mt-1 justify-content-between text-center">
                                            <div className="col-11 text-left">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Top Awarded Cage Codes</span>
                                                </h5>
                                            </div>
                                            <div className="col-1 text-center mt-1">
                                                <span className="" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleExportForm(event, topAwardedCagesRows, 'topAwardedCages')}><FontAwesomeIcon icon={faFileCsv} /></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {
                                topAwardedCagesRows.length > 0 ?
                                    <AdvancedMarketTopAwardedCagesTable rows={topAwardedCagesRows} style={customStylesDark} />
                                    : <div className="row m-0 p-4" style={{
                                        textAlign: 'center'
                                    }}>
                                        <div className="col">
                                            {
                                                topAwardedCagesRowsLoading ?
                                                    <BeatLoader color={"#BF663F"} />
                                                    : <div className="row mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                            }

                                        </div>
                                    </div>
                            }
                            </div>
                        </div>
                        <div className="col-lg-4 m-0 p-0 pl-1 mt-4">
                            <div className="card-element m-0 p-1">
                                <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                    <div className="card" style={{ border: '0' }}>

                                        <div className="card-header p-0">
                                            <div className="row p-0 mt-1 justify-content-between text-center">
                                                <div className="col-10 text-left">
                                                    <h5 className="mb-0">
                                                        <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Top Awarded FSC's</span>
                                                    </h5>
                                                </div>
                                                <div className="col-2 mt-1">
                                                    <span className="ml-3" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleExportForm(event, fscDetailRows, 'topAwardedFscs')}><FontAwesomeIcon icon={faFileCsv} /></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {
                                    fscDetailRows.length > 0 ?
                                        <AdvancedMarketTopFscsTable rows={fscDetailRows} style={customStylesDark} />
                                        : <div className="row m-0 p-4" style={{
                                            textAlign: 'center'
                                        }}>
                                            <div className="col">
                                                {
                                                    fscDetailRowsLoading ?
                                                        <BeatLoader color={"#BF663F"} />
                                                        : <div className="row mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                                }

                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 m-0 p-0 pl-1 mt-4">
                            <div className="card-element m-0 p-1">
                                <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                    <div className="card" style={{ border: '0' }}>

                                        <div className="card-header p-0">
                                            <div className="row p-0 mt-1 justify-content-between text-center">
                                                <div className="col-10 text-left">
                                                    <h5 className="mb-0">
                                                        <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Top Weapon Systems</span>
                                                    </h5>
                                                </div>
                                                <div className="col-2 mt-1">
                                                    <span className="ml-3" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleExportForm(event, wsdcDetailRows, 'topWeaponSystems')}><FontAwesomeIcon icon={faFileCsv} /></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {
                                    wsdcDetailRows.length > 0 ?
                                        <AdvancedMarketTopWsdcTable rows={wsdcDetailRows} style={customStylesDark} />
                                        : <div className="row m-0 p-4" style={{
                                            textAlign: 'center'
                                        }}>
                                            <div className="col">
                                                {
                                                    wsdcDetailRowsLoading ?
                                                        <BeatLoader color={"#BF663F"} />
                                                        : <div className="row mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                                }

                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 m-0 p-0 pl-1 mt-4">
                            <div className="card-element m-0 p-1">
                                <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                    <div className="card" style={{ border: '0' }}>

                                        <div className="card-header p-0">
                                            <div className="row p-0 mt-1 justify-content-between text-center">
                                                <div className="col-10 text-left">
                                                    <h5 className="mb-0">
                                                        <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Top Cage Codes By Approval</span>
                                                    </h5>
                                                </div>
                                                <div className="col-2 mt-1">
                                                    <span className="ml-3" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleExportForm(event, topApprovedCagesRows, 'topCageApprovals')}><FontAwesomeIcon icon={faFileCsv} /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                        topApprovedCagesRows.length > 0 ?
                                            <AdvancedMarketTopApprovedCagesTable rows={topApprovedCagesRows} style={customStylesDark} />
                                        : <div className="row m-0 p-4" style={{
                                            textAlign: 'center'
                                        }}>
                                            <div className="col">
                                                {
                                                    topApprovedCagesRowsLoading ?
                                                        <BeatLoader color={"#BF663F"} />
                                                        : <div className="row mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                                }

                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-0 p-0 col-12 mt-4 card-element" id="graph-bounding-box-2" >
                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                        <div className="card" style={{ border: '0' }}>

                            <div className="card-header p-0">

                                    <div className="row p-0 mt-1 justify-content-between text-center">
                                        <div className="col-11 text-left">
                                            <h5 className="mb-0">
                                                <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Procurement History</span>
                                            </h5>
                                        </div>
                                        <div className="col-1 mt-1">
                                            {
                                                exportLoading ?
                                                    <ClipLoader color={"#BF663F"} size={"20"} />
                                                    : <span className="ml-3" title="Export To Excel" style={{ cursor: 'pointer' }} onClick={(event) => exportProcHist()}><FontAwesomeIcon icon={faFileCsv} size="lg" /></span>
                                            }
                                        </div>
                                    </div>

                            </div>

                        </div>

                    </div>

                    {
                        procHistRows.length > 0 ?
                            <AdvancedMarketProcHistTable rows={procHistRows} style={customStylesDark} />
                            : <div className="row m-0 p-4" style={{
                                textAlign: 'center'
                            }}>
                                <div className="col">
                                    {
                                        procHistRowsLoading ?
                                            <BeatLoader color={"#BF663F"} />
                                            : <div className="row mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                    }
                                </div>
                            </div>
                    }

                    </div>
                </div>
            </>
        )
    };

    return (
        <>
            <PageHeader pageName={'Advanced Market Report'} />

            <div className="row m-0 mt-4 mb-4 p-0 justify-content-center">
                <div className="m-0 p-0 col-12 mb-4 card-element" id="graph-bounding-box">
                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>

                        <div className="card-header p-0" id="filterResults">
                            <h6 className="mb-0">
                                <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseFilterResults" aria-expanded="false" aria-controls="collapseFilterResults" style={{ padding: '0' }} >
                                    <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />
                                </button>

                                <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '100px' }}>Search Criteria</span>

                            </h6>
                        </div>

                        <div id="collapseFilterResults" className="collapse" aria-labelledby="filterResults" data-parent="#accordion">
                            <div className="card-body accordian">
                                <form>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label">Approved Cages</label>
                                                <CreatableSelect
                                                    components={{
                                                        DropdownIndicator: null
                                                    }}
                                                    inputValue={approvedCagesInputValue}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={(newValue) => setApprovedCagesValue(newValue)}
                                                    onInputChange={(newValue) => setApprovedCagesInputValue(newValue)}
                                                    onKeyDown={handleApprovedCagesKeyDown}
                                                    placeholder="Type something and press enter..."
                                                    value={approvedCagesValue}
                                                    key="cages-list"
                                                />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <label className="form-label">Distribution Cages</label>
                                                <CreatableSelect
                                                    components={{
                                                        DropdownIndicator: null
                                                    }}
                                                    inputValue={distCagesInputValue}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={(newValue) => setDistCagesValue(newValue)}
                                                    onInputChange={(newValue) => setDistCagesInputValue(newValue)}
                                                    onKeyDown={handleDistCagesKeyDown}
                                                    placeholder="Type something and press enter..."
                                                    value={distCagesValue}
                                                    key="cages-list"
                                                    isDisabled={false}
                                                />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <label className="form-label">Awardee Cages</label>
                                                <CreatableSelect
                                                    components={{
                                                        DropdownIndicator: null
                                                    }}
                                                    inputValue={awardeeCagesInputValue}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={(newValue) => setAwardeeCagesValue(newValue)}
                                                    onInputChange={(newValue) => setAwardeeCagesInputValue(newValue)}
                                                    onKeyDown={handleAwardeeCagesKeyDown}
                                                    placeholder="Type something and press enter..."
                                                    value={awardeeCagesValue}
                                                    key="cages-list"
                                                    isDisabled={false}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-8 col-sm-4 col-md-3 col-lg-2">
                                                <label className="form-label">Start Date</label>
                                                <Input id="startDate"
                                                    name="startDate"
                                                    placeholder="MM/DD/YYYY"
                                                    onChange={(event) => handleStartDateChange(event)}
                                                    type="date" />
                                            </div>
                                            <div className="col-8 col-sm-4 col-md-3 col-lg-2">
                                                <label className="form-label">End Date</label>
                                                <Input id="endDate"
                                                    name="endDate"
                                                    placeholder="MM/DD/YYYY"
                                                    onChange={(event) => handleEndDateChange(event)}
                                                    type="date" />
                                            </div>
                                        </div>
                                        <br />
                                        <h5 className="mb-0">
                                            <span className=" px-2" style={{ padding: '0', verticalAlign: 'middle' }} >
                                                <FontAwesomeIcon icon={faAngleRight} style={{ width: '20px' }} />
                                            </span>

                                            <button className="btn btn-sm btn-primary my-2" onClick={(event) => handleFormSubmit(event)}>Load Data</button>
                                            <button className="btn btn-sm btn-primary my-2 ml-2" disabled={!headerData} onClick={(event) => handleDownloadPdf(event)}>Export</button>
                                            <button className="btn btn-sm btn-primary my-2 ml-2" onClick={(event) => handleClearCriteria(event)}>Clear</button>
                                        </h5>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/*{dataLoaded ? renderParams() : null}*/}
                {headerData !== null ?
                    renderBody()
                    : <div className="row m-0 p-4" style={{
                        textAlign: 'center'
                    }}>
                        <div className="col align-self-center">
                            {
                                dataLoading ?
                                    <GridLoader color={"#BF663F"} />
                                    : <div className="mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                            }
                        </div>
                    </div>
                }
                
            </div>
        </>

    )
}