import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

export const StockOnHandTable = ({ data, style }) => {

    const formattedData = data.toSorted((a, b) => b.date_stamp - a.date_stamp).slice(0, 12);
    const monthMatrix = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec"
    }

    const monthlyColumns = () => {
        let columns = [];
        formattedData.forEach(e => {
            columns.push({
                name: monthMatrix[e.month] + ' ' + e.year ,
                selector: row => row[e.month + '_' + e.year],
            })
        })

        return columns.reverse();
    };

    const monthlyColumnsV2 = () => {
        let currentDate = new Date();
        let columns = []

        for (let i = 0; i < 12; i++) {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            columns.push({
                name: `${monthMatrix[month]} ${year}`,
                selector: row => row[`${month}_${year}`] !== undefined ? row[`${ month }_${year}`] : 'N/A'
            })
        }
        console.log(singleRow())
        return columns.reverse();
        
    }

    const singleRow = () => {
        let row = {};
        formattedData.forEach(e => {
            row[e.month + '_' + e.year] = e.total_stock;
        })

        return [row];
    };

    return (
        <>
            <DataTable
                columns={monthlyColumnsV2()}
                data={singleRow()}
                customStyles={style}
                dense
            />
        </>
    );
}
