import React, { useEffect, useRef, useState } from "react";
import DataTable from 'react-data-table-component';

import { ReportsApi } from "../../api/ReportsApi";
import { PageHeader } from '../../components/shared/PageHeader'

import { ForecastChartDashboard } from '../../components/reports/ForecastChartDashboard';

import { ForecastWsdcTable } from '../../components/reports/ForecastWeaponSystemsTable';
import { ForecastDemandByClassification } from '../../components/reports/Charts/ForecastedDemandByClassification';

import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

import { NiinRow } from '../../components/shared/NiinRow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faCalendarDays, faChartSimple, faFileDownload, faFileCsv } from '@fortawesome/free-solid-svg-icons';

import CreatableSelect from 'react-select/creatable';

import ClipLoader from 'react-spinners/ClipLoader';
import GridLoader from 'react-spinners/GridLoader';
import BeatLoader from 'react-spinners/BeatLoader';


export const ReportsForecast = ({ theme }) => {

    const [dataLoading, setDataLoading] = React.useState(false);
    const [exportLoading, setExportLoading] = React.useState(false);

    const [includeCagesInputValue, setIncludeCagesInputValue] = React.useState('');
    const [includeCagesValue, setIncludeCagesValue] = React.useState([]);

    const [excludeFSCsInputValue, setExcludeFSCsInputValue] = React.useState('');
    const [excludeFSCsValue, setExcludeFSCsValue] = React.useState([]);

    const [cagesSearchedValue, setCagesSearchedValue] = React.useState([]);

    const [forecastRows, setForecastRows] = useState([])

    const [wsdcRows, setWsdcRows] = useState([]);
    const [wsdcRowsLoading, setWsdcRowsLoading] = useState([]);

    const [currentTheme, setCurrentTheme] = useState([])

    const [searchDisabled, setSearchDisabled] = useState(false);


    const createOption = (label) => ({
        key: label,
        label,
        value: label
    });

    const handleIncludeCagesKeyDown = (event) => {
        if (!includeCagesInputValue) return;
        switch (event.key) {
            case ' ':
            case 'Enter':
            case 'Tab':
                setIncludeCagesValue((prev) => [...prev, createOption(includeCagesInputValue.toUpperCase())]);
                setIncludeCagesInputValue('');
                event.preventDefault();
        }
    };

    const handleExcludeFSCsKeyDown = (event) => {
        if (!excludeFSCsInputValue) return;
        switch (event.key) {
            case ' ':
            case 'Enter':
            case 'Tab':
                setExcludeFSCsValue((prev) => [...prev, createOption(excludeFSCsInputValue)]);
                setExcludeFSCsInputValue('');
                event.preventDefault();
        }
    };


    const getForecastRows = () => {
        setDataLoading(true);
        if (includeCagesValue.length > 0) {
            var cageList = includeCagesValue.map(c => c.value).join(",");

            ReportsApi.forecast({ cageCodes: cageList })
                .then(res => {
                    setForecastRows(res.data)
                    setCagesSearchedValue(includeCagesValue);
                })
                .catch(err => console.log(err))
                .finally(() => setDataLoading(false));
        }
    }

    const getWsdcRows = () => {
        setWsdcRowsLoading(true);
        if (includeCagesValue.length > 0) {
            var cageList = includeCagesValue.map(c => c.value).join(",");

            ReportsApi.forecastWsdc({ cageCodes: cageList })
                .then(res => {
                    setWsdcRows(res.data)
                })
                .catch(err => console.log(err))
                .finally(() => setWsdcRowsLoading(false));
        }
    }

    const handleFormSubmit = () => {
        if (includeCagesValue.length == 0) {
            alert('Must provide at least one cage code.');
            return;
        }

        getForecastRows();
        getWsdcRows();
    }

    const handleExport = () => {
        setExportLoading(true);
        if (includeCagesValue.length > 0) {
            var cageList = includeCagesValue.map(c => c.value).join(",");

            ReportsApi.exportForecast({ cageCodes: cageList })
                .then(res => {
                    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = 'consumption.xlsx';

                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                })
                .catch(err => console.log(err))
                .finally(() => setExportLoading(false));
        }
            
        
    }

    const handleExportCsv = (event, items, type) => {
        event.preventDefault();
        exportCsv(items, type);
    };

    const exportCsv = (items, type) => {
        let csv = ""

        // Loop the array of objects
        for (let row = 0; row < items.length; row++) {
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if (row === 0) {

                // Loop each property of the object
                for (let key in items[row]) {

                    // This is to not add a comma at the last cell
                    // The '\r\n' adds a new line
                    csv += '"' + key + '"' + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
            } else {
                for (let key in items[row]) {
                    csv += '"' + items[row][key] + '"' + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    keysCounter++
                }
            }

            keysCounter = 0
        }

        // Once we are done looping, download the .csv by creating a link
        let link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', type + '.csv');
        document.body.appendChild(link)
        document.querySelector('#download-csv').click()
    }


    const forecastTableColumns = [
        {
            name: 'FSC',
            selector: row => row.fsc
        },
        {
            name: 'NIIN',
            selector: row => row.niin,
            cell: (row, ix) => <>
                <NiinRow ix={ix} niin={row.niin} />
            </>
        },

        {
            name: 'Classification',
            selector: row => row.classification_unified_cages,
        },
        {
            name: 'DLA Stock on Hand',
            selector: row => row.current_stock_on_hand_value,
            sortable: true,
            center: true,
            cell: row => <>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <span>
                        {(row.current_stock_on_hand_value !== null) ? row.current_stock_on_hand_value.toLocaleString() : ''}
                    </span>

                    <a onClick={() => alert("TODO: Show details on last updated: " + row.current_stock_date)} style={{ display: 'inline-block', float: 'right', 'cursor': 'pointer' }}>
                        <FontAwesomeIcon icon={faCalendarDays} />
                    </a>

                    <a onClick={() => alert("TODO: Show history graph")} style={{ display: 'inline-block', float: 'right', marginRight: '10px', 'cursor': 'pointer' }}>
                        <FontAwesomeIcon icon={faChartSimple} />
                    </a>
                </div>

            </>
        },

        {
            name: 'Annual Consumption',
            selector: row => row.annual_consumption,
            center: true,
            cell: row => <>{(row.annual_consumption !== null) ? row.annual_consumption.toLocaleString() : ''}</>,
            sortable: true
        },

        {
            name: 'Ann. Consumption Value',
            selector: row => row.annual_consumption_value,
            center: true,
            cell: row => <>{CurrencyFormatter.format(row.annual_consumption_value)}</>,
            sortable: true
        },

        {
            name: 'Gov Est 12M Demand',
            selector: row => row.gov_est12_mo_purchase_qty,
            center: true,
            sortable: true,
            cell: row => (row.gov_est12_mo_purchase_qty !== null) ? row.gov_est12_mo_purchase_qty.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : null
        },

        {
            name: 'Gov Est 24M Demand',
            selector: row => row.gov_est24_mo_purchase_qty,
            center: true,
            cell: row => <>{(row.gov_est24_mo_purchase_qty !== null) ? row.gov_est24_mo_purchase_qty.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }) : ''}</>,
            sortable: true

        },

        {
            name: 'Last GPH Cage',
            selector: row => row.last_gph_cage,
            center: true,
        },




    ];

    /*
    const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            // use the selector to resolve your field names by passing the sort comparators
            let aField = selector(a);
            let bField = selector(b);

            if (aField == null)
                aField = 0;
            if (bField == null)
                bField = 0;

            let comparison = 0;

            try {
                if (parseFloat(aField) > parseFloat(bField)) { 
                    comparison = 1;
                } else if (parseFloat(aField) > parseFloat(bField)) {
                    comparison = -1;
                }
            }
            catch {
                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }
            }
            

            return direction === 'desc' ? comparison * -1 : comparison;
        });
    };
    */



    const customStylesDark = {
        table: {
            style: {
                fontSize: '12px'
            }
        },
        header: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        headRow: {

            style: {
                padding: "6px 8px 6px 8px",
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        rows: {
            style: {
                padding: "6px 8px 6px 8px",
                '&:nth-of-type(even)': {
                    color: 'white',
                    backgroundColor: '#1D2839'
                },
                '&:nth-of-type(odd)': {
                    color: 'white',
                    backgroundColor: '#39445C'
                },
            },
        },
        pagination: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
                borderTop: '1px solid white'
            }
        }
    };

    const customStylesLight = {

        header: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        headRow: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        rows: {
            style: {
                '&:nth-of-type(even)': {
                    color: 'black',
                    backgroundColor: '#e8e8e8'
                },
                '&:nth-of-type(odd)': {
                    color: 'black',
                    backgroundColor: 'white'
                },
            },
        }
    };

    const renderBody = () => {
        return (
            <>
                {
                    forecastRows.length > 0 ?
                        <div className="row m-0 mb-4 p-0" style={{
                            fontSize: '13px',
                            overflow: 'hidden'
                        }} >
                            <ForecastChartDashboard forecast={forecastRows} />
                        </div>
                        : null
                }

                <div className="m-0 p-0 col-12 mb-4 mt-4 card-element" id="graph-bounding-box-2" style={{ minHeight: '300px' }}>
                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                        <div className="card" style={{ border: '0' }}>

                            <div className="card-header p-0">
                                <div className="row p-0 mt-1 justify-content-between text-center">
                                    <div className="col-11 text-left">
                                        <h5 className="mb-0">
                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Results</span>
                                        </h5>
                                    </div>
                                    <div className="col-1 mt-2">
                                        {
                                            exportLoading ?
                                                <ClipLoader color={"#BF663F"} size={"20"}/>
                                                : <span className="ml-3" title="Export To Excel" style={{ cursor: 'pointer' }} onClick={(event) => handleExport()}><FontAwesomeIcon icon={faFileDownload} size="lg" /></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="card-header p-0" id="filterResults">*/}
                        {/*    <h6 className="mb-0">*/}
                        {/*        <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseFilterResults" aria-expanded="false" aria-controls="collapseFilterResults" style={{ padding: '0' }} >*/}
                        {/*            <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />*/}
                        {/*        </button>*/}

                        {/*        <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '100px' }}>Filter Results:</span>*/}

                        {/*    </h6>*/}
                        {/*</div>*/}

                        {/*<div id="collapseFilterResults" className="collapse" aria-labelledby="filterResults" data-parent="#accordion">*/}
                        {/*    <div className="card-body accordian">*/}
                        {/*        <form>*/}
                        {/*            <div className="form-group">*/}
                        {/*                <label className="form-label">Weapon Systems</label>*/}
                        {/*                <input type="text" className="form-control" placeholder="Start typing a weapon system name to filter.. " />*/}
                        {/*            </div>*/}
                        {/*        </form>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>

                    <DataTable
                        columns={forecastTableColumns}
                        data={forecastRows}
                        customStyles={customStylesDark}
                        pagination
                        dense
                    />
                </div>
                <div className="col-12 m-0 p-0 pr-1 mt-4">
                    <div className="card-element m-0 p-1">

                        <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                            <div className="card" style={{ border: '0' }}>

                                <div className="card-header p-0">
                                    <div className="row p-0 mt-1 justify-content-between text-center">
                                        <div className="col-11 text-left">
                                            <h5 className="mb-0">
                                                <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Weapon Systems</span>
                                            </h5>
                                        </div>
                                        <div className="col-1 text-center mt-1">
                                            <span className="" title="Export To CSV" style={{ cursor: 'pointer' }} onClick={(event) => handleExportCsv(event, wsdcRows, 'wsdcForecast')}><FontAwesomeIcon icon={faFileCsv} /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {
                            wsdcRows.length > 0 ?
                                <ForecastWsdcTable rows={wsdcRows} style={customStylesDark} />
                                : <div className="row m-0 p-4" style={{
                                    textAlign: 'center'
                                }}>
                                    <div className="col">
                                        {
                                            wsdcRowsLoading ?
                                                <BeatLoader color={"#BF663F"} />
                                                : <div className="row mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <PageHeader pageName={'Demand Forecast'} />

            <div className="row m-0 mt-4 mb-4 p-0 justify-content-center">
                <div className="m-0 p-0 col-12 mb-4 card-element" id="graph-bounding-box">
                    <div id="accordion">
                        <div className="card">

                            <div className="card-header p-0">
                                <h5 className="mb-0">
                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Search Criteria</span>
                                </h5>
                            </div>

                            <div className="card-header p-0" id="headingOne">
                                <h6 className="mb-0">
                                    <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style={{ padding: '0' }} >
                                        <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />
                                    </button>

                                    <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '60px' }}>Include:</span>

                                    {includeCagesValue.map((o, i) =>
                                        <span className="badge badge-pill badge-secondary" style={{ fontWeight: 'normal', fontSize: '.7rem', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px' }}><b>Cage</b>: {o.label}</span>
                                    )}

                                    {/* 
                                    <span className="badge badge-pill badge-secondary" style={{ fontWeight: 'normal', fontSize: '.7rem', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px' }}><b>NIINs</b>: 20</span>
                                    */}

                                </h6>
                            </div>

                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body accordian">
                                    <form>
                                        <div className="form-group">
                                            <label className="form-label">Cage Codes</label>
                                            <CreatableSelect
                                                components={{
                                                    DropdownIndicator: null
                                                }}
                                                inputValue={includeCagesInputValue}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={(newValue) => setIncludeCagesValue(newValue)}
                                                onInputChange={(newValue) => setIncludeCagesInputValue(newValue)}
                                                onKeyDown={handleIncludeCagesKeyDown}
                                                placeholder="Type something and press enter..."
                                                value={includeCagesValue}
                                                key="cages-list"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>



                            {/*<div className="card-header p-0" id="headingTwo">*/}
                            {/*    <h6 className="mb-0">*/}
                            {/*        <button className="btn btn-link px-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ padding: '0' }} >*/}
                            {/*            <FontAwesomeIcon icon={faAngleDown} style={{ width: '20px' }} />*/}
                            {/*        </button>*/}

                            {/*        <span style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px', width: '60px' }}>Exclude:</span>*/}

                            {/*        {excludeFSCsValue.map((o, i) =>*/}
                            {/*            <span className="badge badge-pill badge-secondary" style={{ fontWeight: 'normal', fontSize: '.7rem', paddingTop: '5px', paddingBottom: '5px', marginRight: '10px' }}><b>FSC</b>: {o.label}</span>*/}
                            {/*        )}*/}

                            {/*    </h6>*/}
                            {/*</div>*/}

                            {/*<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">*/}
                            {/*    <div className="card-body accordian">*/}
                            {/*        <form>*/}
                            {/*            <div className="form-group">*/}
                            {/*                <label className="form-label">FSCs</label>*/}
                            {/*                <CreatableSelect*/}
                            {/*                    components={{*/}
                            {/*                        DropdownIndicator: null*/}
                            {/*                    }}*/}
                            {/*                    inputValue={excludeFSCsInputValue}*/}
                            {/*                    isClearable*/}
                            {/*                    isMulti*/}
                            {/*                    menuIsOpen={false}*/}
                            {/*                    onChange={(newValue) => setExcludeFSCsValue(newValue)}*/}
                            {/*                    onInputChange={(newValue) => setExcludeFSCsInputValue(newValue)}*/}
                            {/*                    onKeyDown={handleExcludeFSCsKeyDown}*/}
                            {/*                    placeholder="Type something and press enter..."*/}
                            {/*                    value={excludeFSCsValue}*/}
                            {/*                    key="fscs-list"*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*        </form>*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            <div className="card-header p-0">
                                <h5 className="mb-0">
                                    <span className=" px-2" style={{ padding: '0', verticalAlign: 'middle' }} >
                                        <FontAwesomeIcon icon={faAngleRight} style={{ width: '20px' }} />
                                    </span>

                                    <button className="btn btn-sm btn-primary my-2" onClick={() => handleFormSubmit()} disabled={searchDisabled}>Load Data</button>
                                </h5>
                            </div>

                        </div>
                    </div>


                </div>

                {
                    forecastRows.length > 0 ?
                        renderBody()
                        : <div className="row m-0 p-4" style={{
                            textAlign: 'center'
                        }}>
                            <div className="col align-self-center">
                                {
                                    dataLoading ?
                                        <GridLoader color={"#BF663F"} />
                                        : <div className="mt-2 ml-5"><h5>No Data Loaded.</h5></div>
                                }
                            </div>
                        </div>
                }

            </div>


        </>
    )
}