import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

export const AdvancedMarketTopFscsTable = ({ rows, style }) => {

    const fscDetailColumns = [
        {
            name: 'FSC',
            selector: row => row.fsc ?? "N/A",
        },
        {
            name: 'Awards',
            selector: row => row.award_count,
            sortable: true
        },
        {
            name: 'Award Value',
            selector: row => row.total_value,
            format: row => CurrencyFormatter.format(row.total_value),
            sortable: true
        },
        {
            name: 'Win Value',
            selector: row => row.win_value,
            format: row => CurrencyFormatter.format(row.win_value),
            sortable: true
        },
    ]

    return (
        <>
            <DataTable
                columns={fscDetailColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}